import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
@Component({
  selector: 'qr-disclaimer',
  templateUrl: './qr-disclaimer.component.html',
  styleUrls: ['./qr-disclaimer.component.scss'],
  standalone: true,
  imports: [L10nTranslatePipe, CommonModule],
})
export class QrDisclaimerComponent {
  @Input() type!: string;
  @Input() theme!: string;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}
}
